body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins", sans-serif;
}

button {
  cursor: pointer;
}

svg.recharts-surface {
  width: 100% !important;
}

.pie svg.recharts-surface {
  width: 200px;
  height: 200px;
}

.circular > div[data-test-id="CircularProgressbarWithChildren"] > div {
  width: 24rem !important;
  height: 24rem !important;
  margin: 0 auto;
}

.pointer{
    cursor: pointer !important;
}
.pl-20{
    padding-left: 20px
  }
.absolute{
    position: absolute;
}
.pending{
    color: #e5b909;
}
.disapproved{
    color: #FF1E1E;
}
.deleted{
    color: #a2a2a2;
}
.canceled{
    color: #FF1E1E;
}
.failed{
    color: #FF1E1E;
}
.successful{
    color: #27AE60
}
.active{
    color: #27AE60
}
.pendingverification{
    color: #e5b909;
}
.approved{
    color: #FF1E1E;
}
.inactive{
    color: #a2a2a2;
}

/* QR code background */
.QRcode-bg {
    position: relative;
    border: 3px solid #E5B910;
    border-radius: 12px;
    background: #fff;
    transition: all 0.05s linear;
  }
  
  .QRcode-bg:before,
  .QRcode-bg:after {
    content: "";
    position: absolute;
    background: #fff;
    transition: all 0.2s linear;
  }
  
  .QRcode-bg:before {
    width: calc(100% + 9px);
    height: calc(100% - 64px);
    top: 32px;
    left: -4px;
  }
  
  .QRcode-bg:after {
    height: calc(100% + 9px);
    width: calc(100% - 64px);
    top: -3px;
    left: 32px;
  }

  .QRcode-bg img {
    height: 120px;
    padding: 10px;

  }

  .mt-40{
      margin-top: 40px;
  }
  .mt-30{
    margin-top: 30px;
}
.mb-0{
  margin-bottom: 0px;
}
.t-center{
  text-align: center;
}
  .z-4{
      z-index: 4;
  }

  .relative{
      position: relative;
  }
  .copy-position{
    position: absolute;
    right: 5%
  }
  .authenticator{
    color: var(--primary-yellow, #E5B910);
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .t-center{
    text-align: center;
  }
  .budgetCurrency{
    margin: 3px;
    padding-left: 4px;
  }